import React, { useState } from "react"
import "./DiscountsList.scss"
import { isEmpty } from "lodash"
import vplab from "./images/vplab.png"
import triomed from "./images/triomed.png"
import anytime from "./images/anytime.png"
import sstby from "./images/sstby.png"

const discounts = [
  {
    key: "vplab",
    name: "vplab outlet",
    logo: vplab,
    discount: '<span class="stress">10%</span>',
    short_descxription: "СПОРТИВНОЕ И ДИЕТИЧЕСКОЕ ПИТАНИЕ, ВИТАМИНЫ, БАД",
    content: [
      "<b>VPLab Outlet (ВиПиЛэб Аутлет)</b> – это самая широкая в республике Беларусь сеть магазинов по продаже спортивного питания, товаров для красоты, здоровья и активного долголетия.",
      "Помимо традиционных добавок для скоростно-силовых и циклических видов спорта <b>VPLab Outlet</b> предлагает широкий спектр продукции для общего укрепления здоровья, красоты и активного долголетия – CoQ10 и гиалуроновая кислота, Omega-3 и L-карнитин, коллаген и хондропротекторы, витаминно-минеральные комплексы и средства для поддержки иммунитета.",
      "Все товары, представленные с сети <b>VPLab Outlet</b>, сертифицированы и официально завезены на территорию таможенного союза, что гарантирует безопасность** для здоровья и соблюдение прав потребителей.",
      'В сети  <b>VPLab Outlet</b> и на сайте <a href="vplaboutlet.by" target="_blank">vplaboutlet.by</a> держателям карт «allsports» предоставляется скидка <span class="stress">10%</span>*.',
      "<b>Шаг 1:</b> выбирайте товары на сайте vplaboutlet.by или в offline-сети магазинов <b>VPLab Outlet</b>",
      "<b>Шаг 2:</b> для получения скидки предъявите карту «Allsports» продавцу в магазине или введите промокод «allsports» в соответствующее поле в «корзине» при покупке на сайте vplaboutlet.by",
      '<span class="small">* скидка не распространяется на продукцию брендов «animal», «bizon» и «утки-дудки», не действует при оплате по картам рассрочек и с прочими акциями сети не суммируется. </span>',
      '<span class="small">** информация, носит исключительно информационно-рекламный характер</span>',
    ],
  },
  {
    key: "triomed",
    name: "triomed",
    logo: triomed,
    discount: 'до <span class="stress">30%</span>',
    short_descxription: "Стоматологическая клиника",
    content: [
      "<b>Triomed Club:</b> нет – залечиванию, да – здоровью!",
      "Мы стоматологическая клиника Triomed Club, для которой важно, чтобы ваши зубы остались с вами надолго!<br/>\n" +
        'Наш сайт: <a target="_blank" href="https://triomedclub.by">triomedclub.by</a><br/>\n' +
        "Адрес: г. Минск , пр-т Дзержинского, 20-212<br/>\n" +
        "Время работы: Пн-Пт: 9.00-21.00, Сб: 9.00-18.00<br/>\n" +
        'Контакты:   <a href="tel:+375173881606">+375 (17) 388-16-06</a>  <a href="tel:+375447199975">+375 (44) 719-99-75</a> <br/>',
      "Лечение в нашей клинике основывается только на полной диагностике. В одном центре мы оказываем  весь спектр стоматологических услуг:лечение кариеса, отбеливание, хирургическое лечение, протезирование, имплантацию, исправление прикуса, детское лечение, компьютерную томографию, детское лечение. Наши врачи прошли тщательной отбор и являются специалистами с многолетний опытом и нестандартными кейсами.Мы работаем на оборудовании последнего поколения:аппарат KAVO 3D Pro, система Cerec для изготовления керамической реставрации в 1 посещение. Используем качественные материалы для пломбировки, надёжные системы имплантации, эффективные ортодонтические конструкции. <br/>\n" +
        "Наши плюсы: гарантия на лечение до 5 лет; личный куратор лечения; комфортная обстановка; работаем со страховыми компаниями и материнским капиталом.",
      "В Triomed Club держателям карт Allsports предоставляются скидки:<br/>\n" +
        "-первичная консультация терапевта: <b>БЕСПЛАТНО</b><br/>\n" +
        "-скидка на терапевтическое лечение <b>10 %</b><br/>\n" +
        "-скидка на профессиональную гигиену полости рта <b>15%</b><br/>\n" +
        "-скидка на КЛКТ(3D томография) <b>30%</b><br/>\n" +
        "Для получения скидки предъявите карту «Allsports» при заказе услуги.",
      '<span class="small">* скидка не действует при оплате по картам рассрочек и с прочими акциями не суммируется</span>',
    ],
  },
  {
    key: "anytime",
    name: "anytime",
    logo: anytime,
    discount: '<span class="stress">9 BYN*</span>',
    short_descxription: "ПРИ РЕГИСТРАЦИИ",
    content: [
      "Anytime — первый и самый крупный каршеринг в Минске.<br/>\nСервис поминутной аренды автомобиля для водителей от 19 лет со стажем от одного года. Платите только за минуты в пути. Про платные парковки, поездки на заправку, лишние траты на ремонт и обслуживание придется забыть. Бронируйте автомобили через мобильное приложение, открывайте и закрывайте там же.",
      '﻿﻿﻿﻿﻿﻿﻿﻿﻿Держателям карт Allsports в приложении <a href="https://anytimeby.onelink.me/V6SW/10835ee6" target="_blank">Anytime</a> предоставляется 9 бонусных баллов при регистрации. 1 бонусный балл=1 BYN, который можно потратить для оплаты поездок.',
      '<b>Шаг 1:</b> зарегистрируйтесь в приложении <a href="https://anytimeby.onelink.me/V6SW/10835ee6">https://anytimeby.onelink.me/V6SW/10835ee6</a>',
      '<b>Шаг 2:</b> для получения бонуса в разделе «Бонусы и промокоды» в поле для ввода промокода введите промокод <span class="stress">ALLSPORTS</span>',
      '<span class="small">*Бонусными баллами можно оплатить до 50% стоимости любой поездки. Начисленные бонусные баллы не сгорают и могут быть использованы в любое время.</span>',
    ],
  },
  {
    key: "sst/by",
    name: "SST/BY",
    logo: sstby,
    discount: '<span class="stress">7%*</span>',
    short_descxription: "НА ВСЕ ФИТНЕС-ОБОРУДОВАНИЕ И АКСЕССУАРЫ",
    content: [
      "Салон спортивных тренажеров предлагает лучшие тренажеры, фитнес-аксессуары и профессиональное оборудование для дома и зала.<br/>\n" +
        "Успешно работаем на рынке СНГ более 17 лет.",
      'Держателям карт Allsports на сайте <a href="https://sst.by/" target="_blank">https://sst.by/</a>  по промокоду ALLSPORTS предоставляется скидка <span class="stress">7%</span> на весь ассортимент. ',
      "Скидка работает и в шоурумах, находящихся по адресу:<br/> \nУманская, 54, ТЦ «Глобо», 0-й уровень,<br/>\nпр-т Победителей, 65, ТЦ «Замок», 4-й этаж.",
      "<b>Шаг 1:</b> выбирайте товары в интересующем вас разделе",
      '<b>Шаг 2:</b> для получения скидки на сайте <a href="https://sst.by/" target="_blank">https://sst.by/</a> в «корзине» в поле для ввода промокода введите <span class="stress">ALLSPORTS</span> <br/>\nПри осуществлении покупок в салонах SST назовите промокод Allsports на кассе.',
      '<span class="small">*Скидка по промокоду суммируются с акционными предложениями, но не суммируется со скидкой именинника, пенсионера.<br/>\nПромокод не работает при оплате частями (в том числе картами рассрочки).</span>',
    ],
  },
]

function DiscountsList() {
  const [activePartner, setActivePartner] = useState(null)

  const close = () => {
    setActivePartner(null)
  }
  const handleClickOverlay = (event) => {
    event.stopPropagation()
    event.preventDefault()
    close()
  }
  const handleClick = (event) => {
    event.stopPropagation()
  }
  const handleKeyUpOverlay = (event) => {
    if (event.key === "Escape") {
      close()
    }
  }
  return (
    <div className="discounts-list">
      <h3>Партнеры Allsports+</h3>

      <div className="steps">
        {discounts.map((partner, i) => {
          return (
            <div key={i} onClick={() => setActivePartner(partner)}
            onChange={()=>{}} aria-hidden="true">
              <img src={partner.logo} alt={partner.name} />
              <span dangerouslySetInnerHTML={{ __html: partner.discount }} />
              <p>{partner.short_descxription}</p>
            </div>
          )
        })}
      </div>
      {!isEmpty(activePartner) && (
        <div
          tabIndex="0"
          role="button"
          className="step"
          onClick={handleClickOverlay}
          onKeyUp={handleKeyUpOverlay}
          aria-hidden="true"
        >
          <div className="content" onClick={handleClick}
          onChange={()=>{}} aria-hidden="true">
            <button className="close" onClick={close}>
              x
            </button>
            <img
              className="img-header"
              src={activePartner.logo}
              alt={activePartner.name}
            />
            {activePartner.content.map((value, i) => {
              return <p key={i} dangerouslySetInnerHTML={{ __html: value }} />
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default DiscountsList

import React from "react"

import Layout from "src/components/layout"
import Head from "src/components/Head"

import DiscountsRules from "../../components/discounts/DiscountsRules/DiscountsRules"
import DiscountTop from "../../components/discounts/DiscountTop/DiscountTop"
import DiscountsList from "../../components/discounts/DiscountsList/DiscountsList"

import { graphql, useStaticQuery } from "gatsby"

const DiscountsMain = ({ location }) => {

  const data = useStaticQuery(graphql`
    query skidkiPageClientsBy {
      site {
        siteMetadata {
          senderPath
          countries {
            by {
              hotLine {
                tel
                title
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout
      hotLine={data.site.siteMetadata.countries.by.hotLine}
      location={location}
      country="by"
    >
      <Head
        lang="by"
        title="Дисконтная программа Allsports"
        description="Дисконтная программа для обладателей карт Allsports"
        slug="/discounts/"
      />
      <DiscountTop type="discounts" />
    </Layout>
  )
}

export default DiscountsMain

import React from "react"
import "./DiscountsRules.scss"

function DiscountsRules() {
  return (
    <section className="discounts-rules">
      <h1>Правила пользования программой Allsports+</h1>
      <p>
        Предъявите подписку Allsports или используйте промокод «Allsports» при
        заказе товара/услуги у наших партнеров и получите фиксированную скидку.
      </p>
    </section>
  )
}

export default DiscountsRules
